import React from 'react';

const OpinionPopup =  opinion => ({ feature }) => {
    const name = feature.get('name') || feature.get('name_en')
    const count = feature.get('count')
    const opinionValue = feature.get(opinion)
    return (
      <div>
        <b>{name}</b><br />
        <b>Opinon:</b> {opinionValue.toFixed(2)}<br />
        <b>Participants:</b> {count}
      </div>
    );
  };

  export default OpinionPopup