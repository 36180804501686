import React, { Component } from 'react'
import MVTFormat from 'ol/format/MVT'
import VectorTileLayer from 'ol/layer/VectorTile'
import Map from 'ol/Map';
import { get as getProjection } from 'ol/proj.js'
import VectorTileSource from 'ol/source/VectorTile'
import Circle from 'ol/style/Circle'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import TileGrid from 'ol/tilegrid/TileGrid.js'

import './App.css'

import BasicMap from 'react-spatial/components/map/BasicMap'
import Layer from 'react-spatial/Layer'
import ActionLink from 'react-spatial/components/link/ActionLink'
import PopupHandler from 'react-spatial/components/popup/PopupHandler';

import OpinionPopup from './OpinionPopup'

const green = [65, 199, 199]
const red = [254, 106, 106]

const opinions = {
  direction: 'JavaScript is moving in the right direction',
  complex: 'Building JavaScript apps is overly complex right now',
  overused: 'JavaScript is over-used online',
  enjoy: 'I enjoy building JavaScript apps',
  main: 'I would like JavaScript to be my main programming language',
  fast: 'The JavaScript ecosystem is changing too fast',
}

function getGradient(weight) {
  const w1 = ((weight * 2 - 1) / 1 + 1) / 2
  const w2 = 1 - w1
  return [
    Math.round(green[0] * w1 + red[0] * w2),
    Math.round(green[1] * w1 + red[1] * w2),
    Math.round(green[2] * w1 + red[2] * w2),
  ]
}

class App extends Component {
  constructor(props) {
    super(props)

    this.map = new Map({ controls: [] })

    this.state = { menuOpen: false, opinion: 'fast' }

    const countrySource = new VectorTileSource({
      format: new MVTFormat(),
      url: process.env.PUBLIC_URL + '/tiles/countries/{z}/{x}/{y}.pbf',
    })

    this.backgroundLayer = new Layer({
      name: 'Background',
      olLayer: new VectorTileLayer({
        source: countrySource,
        style: new Style({
          fill: new Fill({ color: '#EEE' }),
          stroke: new Stroke({ color: '#DDD', width: 1 }),
        }),
      }),
    })

    this.countryLayer = new Layer({
      name: 'Countries',
      olLayer: new VectorTileLayer({
        source: countrySource,
        style: this.getCountryStyle(this.state.opinion),
      }),
    })

    this.cityLayer = new Layer({
      name: 'Cities',
      olLayer: new VectorTileLayer({
        source: new VectorTileSource({
          format: new MVTFormat(),
          tileGrid: new TileGrid({
            extent: getProjection('EPSG:3857').getExtent(),
            resolutions: [78271.51696402048],
            tileSize: 512,
          }),
          url: process.env.PUBLIC_URL + '/tiles/cities/{z}/{x}/{y}.pbf',
        }),
        style: this.getCityStyle(this.state.opinion),
      }),
    })
  }

  toggleLayer(layer) {
    this[layer].setVisible(!this[layer].getVisible())
    this.setState({ [layer]: this[layer].getVisible() })
  }

  getCountryStyle(opinion) {
    const fill = new Fill({ color: '' })
    const overlay = new Style({
      fill,
      stroke: new Stroke({ color: '#DDD', width: 1 }),
    })
    return function(feature) {
      const count = feature.get('count')
      const opinionValue = feature.get(opinion)
      if (opinionValue && count >= 10) {
        const weight = Math.max(opinionValue - 0, 0) / 5
        const rgb = getGradient(weight)
        fill.setColor(`rgba(${rgb[0]},${rgb[1]},${rgb[2]},1)`)
      } else {
        fill.setColor('#EEE')
      }
      return [overlay]
    }
  }

  getCityStyle(opinion) {
    const fill = new Fill({ color: '' })
    const stroke = new Stroke({ color: '#777', width: 1 })
    const circle = new Circle({ radius: 1, fill, stroke })
    const point = new Style({ image: circle })
    return function(feature) {
      const weight = Math.max(feature.get(opinion) - 0, 0) / 5
      const rgb = getGradient(weight)
      const radius = Math.sqrt(feature.get('count') / Math.PI) * 2
      fill.setColor(`rgba(${rgb[0]},${rgb[1]},${rgb[2]},1)`)
      circle.setRadius(radius)
      return [point]
    }
  }

  setOpinion(opinion) {
    const cityStyle = this.getCityStyle(opinion)
    const countryStyle = this.getCountryStyle(opinion)
    this.cityLayer.setStyle(cityStyle)
    this.countryLayer.setStyle(countryStyle)
    this.setState({ menuOpen: false, opinion })
  }

  onFeaturesHover(features) {
    // console.log(features)
  }

  onFeaturesClick(features) {
    this.setState({popupFeature: features[0]})
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <div className="App-header-content">
            <div className="App-title">
              <h1>Opinions of JS 2018</h1>
              <h2>{opinions[this.state.opinion]}</h2>
            </div>
            <div className="App-menu">
              {this.state.menuOpen &&
                Object.entries(opinions)
                  .filter(([opinion]) => opinion !== this.state.opinion)
                  .map(([opinion, label]) => (
                    <h3 key={opinion}>
                      <ActionLink
                        onClick={() => this.setOpinion(opinion)}
                        label={label}
                        title={label}
                      />
                    </h3>
                  ))}
            </div>
          </div>
          <div className="App-menu-handler">
            <ActionLink
              onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
              label={this.state.menuOpen ? '▲' : '▼'}
              title={this.state.menuOpen ? 'close menu' : 'open menu'}
            />
          </div>
        </div>
        <BasicMap
          map={this.map}
          onFeaturesClick={features => this.onFeaturesClick(features)}
          onFeaturesHover={features => this.onFeaturesHover(features)}
          zoom={2}
          maxZoom={8}
          layers={[this.backgroundLayer, this.countryLayer, this.cityLayer]}
        />
        <PopupHandler
          map={this.map}
          feature={this.state.popupFeature}
          ContentComponent={OpinionPopup(this.state.opinion)}
        />
        <div className="App-sidebar-wrapper">
          <div className="App-sidebar">
            <div>Layers</div>
            <ActionLink
              className={this.countryLayer.getVisible() ? 'active' : 'inactive'}
              onClick={() => this.toggleLayer('countryLayer')}
              label="Countries"
              title="Countries"
            />
            <ActionLink
              className={this.cityLayer.getVisible() ? 'active' : 'inactive'}
              onClick={() => this.toggleLayer('cityLayer')}
              label="Cities"
              title="Cities"
            />
            <div>Symbology</div>
            <div className="App-symbology-low">low (0)</div>
            <div className="App-symbology-high">high (5)</div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
